import { render, staticRenderFns } from "./Ichra.vue?vue&type=template&id=42d54f53&lang=pug&"
import script from "./Ichra.vue?vue&type=script&lang=js&"
export * from "./Ichra.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Name: require('/codebuild/output/src4172724901/src/frontend/components/Name.vue').default})
