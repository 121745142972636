import { render, staticRenderFns } from "./OnGte65SepLecNoPartAHsaSsiGuidance.vue?vue&type=template&id=d2b39288&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SurveyPartsabSummarySubguidance: require('/codebuild/output/src4172724901/src/frontend/components/survey/PartsabSummarySubguidance.vue').default,SurveyWhenToSignUpSubguidance: require('/codebuild/output/src4172724901/src/frontend/components/survey/WhenToSignUpSubguidance.vue').default,SurveyHowToSignUpPartsabSubguidance: require('/codebuild/output/src4172724901/src/frontend/components/survey/HowToSignUpPartsabSubguidance.vue').default,SurveyWatchOutForSubguidance: require('/codebuild/output/src4172724901/src/frontend/components/survey/WatchOutForSubguidance.vue').default,SurveyGettingCoverageAfterMedicareSubguidance: require('/codebuild/output/src4172724901/src/frontend/components/survey/GettingCoverageAfterMedicareSubguidance.vue').default})
