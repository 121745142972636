import { render, staticRenderFns } from "./Pharmacies.vue?vue&type=template&id=00788636&lang=pug&"
import script from "./Pharmacies.vue?vue&type=script&lang=js&"
export * from "./Pharmacies.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PharmaciesList: require('/codebuild/output/src4172724901/src/frontend/components/PharmaciesList.vue').default,Spinner: require('/codebuild/output/src4172724901/src/frontend/components/Spinner.vue').default})
