import { render, staticRenderFns } from "./DrugEditItem.vue?vue&type=template&id=c2c7c530&lang=pug&"
import script from "./DrugEditItem.vue?vue&type=script&lang=js&"
export * from "./DrugEditItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DrugExternalLinks: require('/codebuild/output/src4172724901/src/frontend/components/DrugExternalLinks.vue').default,DrugForm: require('/codebuild/output/src4172724901/src/frontend/components/DrugForm.vue').default,DrugExternalCosts: require('/codebuild/output/src4172724901/src/frontend/components/DrugExternalCosts.vue').default})
